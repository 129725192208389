import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useState,useEffect } from 'react';

const Popup = (props) => {
  const [open, setOpen] = useState(false);

  const togglePopup = () => {
    setOpen(!open);
  };
  

  const popupHeight = open ? 'h-full' : 'h-0';
  useEffect(() => {
    togglePopup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const descriptionParagraphs = props.description.split('\n').map((line, index) => (
    <p key={index} className='lg:px-8 px-4 text-left pb-4'>
      {/* Use the <strong> tag to make some words bold */}
      {line.includes('*') ? (
        <span>
          {line.split('*').map((text, i) =>
            i % 2 === 0 ? (
              <span key={i}>{text}</span>
            ) : (
              <strong key={i}>{text}</strong>
            )
          )}
        </span>
      ) : (
        line
      )}
    </p>
  ))
;
  return (
    <div className={`fixed lg:px-8 lg:py-8  z-50 top-0 left-0 w-full h-screen flex justify-center items-center ${popupHeight} transition-all duration-1000`}>
      
      
      <div className="bg-gray-300 border-4 h-[80%] border-gray-800 p-4 rounded-xl relative w-full lg:w-[50%] text-black overflow-y-auto overflow-x-hidden">

      <div className='w-full h-[30px] sticky top-0 right-0 ml-2 mb-4'>
        <button className='w-[30px] h-[30px] absolute right-0 z-50 ' onClick={props.onClose}>
        <AiOutlineCloseCircle className='w-[30px] h-[30px] text-gray-800'/>
        </button>
          
        </div>
        
      
        
        <h2 className='pb-4 text-xl font-bold'>{props.title}</h2>
        <p className='lg:px-12 text-left pb-4'>{descriptionParagraphs}</p>
        <button className="z-50 font-bold" onClick={props.onClose}>Close</button>
      </div>
    </div>
  );
};

export default Popup;