import React from 'react'
import img_Project from '../assets/dragon.png'
import img_Project1 from '../assets/project.jpg'
import img_cv from '../assets/computervision.jpg'
import img_cv2 from '../assets/computervision2.jpg'
import img_sa from '../assets/semanticanalysis.jpg'
import img_medussa from '../assets/medussa.jpg'
import { useState } from 'react'
import Popup from './Popup'

function Card(props) {
  const imgs = [img_Project,img_Project1,img_cv,img_cv2,img_sa,img_medussa];

  const [showPopup, setShowPopup] = useState(false);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };


 

  return (
    <div className=" relative w-full h-full min-h-[350px] ">
      {/*Popup*/}
      {showPopup && <Popup title={props.title} description={props.description} onClose={handleClosePopup} />}

      {/*Title Container*/}
      <div className='absolute top-0 left-0 w-full h-full max-h-[10%]'>
       <h1 className='font-bold text-sm lg:text-lg '>{props.title}</h1>
      </div>
      {/* Card Container */}
      <div className='absolute top-[10%] w-full h-[90%] group'>
      <div className='absolute top-0 left-0 w-full h-full flex'>
           <img src={imgs[props.img_Index]} alt='project img' className='rounded-lg z-20 absolute top-0 left-0  w-full h-full object-cover'></img>
              <div className='bg-gradient-to-r from-[#b263fc] from-33% via-[#fba2d5] via-66% to-[#b6f2dd] to-100% w-full h-full rounded-lg opacity-70 filter blur-lg flex px-2  inset-0 group-hover:scale-[100%] delay-300 duration-500 scale-[95%]'>
              </div>
              
        </div>

        <div className='z-20 absolute bottom-0 left-0 inset-x-0 h-[25%] w-full flex flex-col gap-0'>
       
       <button className='text-white bg-gray-300 absolute  bg-opacity-50  border-2 rounded-full px-6 py-3 my-2 flex items-center
        transition ease-in-out delay-250 
        z-20
        left-6
        sm:bottom-2 bottom-0
        h-[30%]
        w-full
        min-w-[70px]
        max-w-[20%]
        hover:bg-gradient-to-b from-[#61ff906b] from-33% via-[#16bd4870]  to-[#13c74969] to-100% 
        group-hover:scale-[110%] 
        group-hover:-translate-y-1 delay-250 duration-500  '>
          <a className='absolute left-0 text-xs  w-full z-20 ' href={props.repo_link} >Code</a>
           
       
        </button>

        <button onClick={handleOpenPopup} className='text-white bg-gray-300 absolute bg-opacity-50 border-2 rounded-full px-6 py-3 my-2 flex items-center
        transition ease-in-out delay-250 
        left-6
        sm:bottom-10 bottom-8
        h-[30%]
        w-full
        min-w-[70px]
        max-w-[20%]
        hover:bg-gradient-to-b from-[#61ff906b] from-33% via-[#16bd4870]  to-[#13c74969] to-100% 
        group-hover:scale-[110%] 
        group-hover:-translate-y-1 delay-150 duration-500 '>
           <p className='absolute left-0 text-xs  w-full '>Details</p>
       
        </button>
        
        
        
      
      </div>
        
      </div>
      
      
    </div>
      
  )
}

export default Card
