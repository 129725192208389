import React from 'react'
import Card from './Card'

const Projects = () => {
  const description = `
  The Tattoo Studio Website is a captivating and modern web application designed and developed for a prominent tattoo studio. The goal of the project was to create an online platform that not only showcases the studio's unique artistry but also provides an engaging user experience for both tattoo enthusiasts and potential clients.  
  *Key Features*: Responsive and Intuitive Design: The website boasts a fully responsive layout, ensuring seamless accessibility across various devices, including desktops, tablets, and smartphones. The intuitive navigation and user-friendly interface offer a smooth browsing experience.
  *Gallery and Portfolio Showcase*: The heart of the website lies in its meticulously curated gallery section, showcasing the studio's extensive portfolio of tattoos. The gallery is presented in a visually stunning manner, allowing users to browse through the artists' exceptional works effortlessly.  
  *Custom Appointment Booking System*: To streamline the appointment booking process, a bespoke booking system was integrated into the website. Clients can easily schedule appointments with their preferred tattoo artists, selecting from available dates and times.
  *Artist Profiles*: Recruiters will be delighted to find that the website features comprehensive profiles for each tattoo artist. These profiles highlight their specialties, experiences, and achievements, providing a valuable glimpse into the talented team behind the studio.
  *Testimonials and Reviews*: To build trust and credibility, the website includes a dedicated section for client testimonials and reviews. These authentic accounts of positive experiences help potential clients feel confident in their decision to choose the studio.
  *Blog and Tattoo Care Resources*: For added value, the website incorporates a blog section that regularly publishes informative articles on tattoo care, aftercare, and trends. This resourceful content establishes the studio as a thought leader in the tattoo industry.
  *Social Media Integration*: The website seamlessly integrates with the studio's social media channels, encouraging users to engage and share their favorite tattoos with their networks. This integration enhances the website's reach and drives organic traffic.
  *The Tattoo Studio Website was built using modern web development technologies, including: *
  Front-end: HTML5, CSS3, JavaScript (ES6)
  Front-end Framework: React.js
  Styling: Tailwind CSS
  Back-end: Node.js with Express.js
  Database: MongoDB
  Deployment: Vercel  
  *Outcome*:The Tattoo Studio Website has been met with resounding success since its launch. It has significantly increased the studio's online visibility, resulting in a surge of new clients and appointment bookings. The seamless user experience and unique features have solidified the studio's reputation as a top-notch destination for tattoo enthusiasts.  
  *Takeaways*:This project allowed me to demonstrate my proficiency in full-stack web development, showcasing my expertise in front-end technologies, responsive design, interactive features, and database integration. The custom booking system and interactive design customizer were particularly well-received by users and have proven my ability to tackle complex challenges.  
  The Tattoo Studio Website serves as a prime example of my passion for creating captivating and functional web experiences that leave a lasting impact on users and achieve tangible business results.`;

  const description_App = `
  The Medussa Tattoo Studio Management Web Application is a powerful and comprehensive platform tailored to meet the specific needs of the tattoo studio's administrative and operational tasks. Developed with efficiency and scalability in mind, this application empowers the studio to streamline customer management, bookings, and overall business operations.  
  *Customer Registration and Database*: The application features a secure customer registration system, enabling the studio to create and maintain a centralized database of clients. Each customer profile includes essential details, appointment history, and preferences, facilitating personalized services and client engagement.  
  *Online Booking and Scheduling*: The studio can bid farewell to manual appointment booking as the web application provides a seamless online booking and scheduling system. Customers can effortlessly book appointments through the platform, and studio administrators can efficiently manage the appointment calendar, reducing scheduling conflicts and optimizing resource allocation.  
  *Automated Reminders and Notifications*: With automated reminders and notifications, the application ensures smooth communication between the studio and its customers. Automated alerts for upcoming appointments, rescheduling options, and special promotions keep clients informed and engaged, enhancing their overall experience.  
  *Inventory and Resource Management*: The application offers a comprehensive inventory and resource management module. Studio administrators can effortlessly monitor and track tattoo supplies, equipment, and other resources. This feature ensures timely restocking, minimizing downtime, and optimizing operational efficiency.  
  *Sales and Revenue Tracking*: The built-in sales and revenue tracking system empowers the studio to monitor financial performance. Real-time insights into sales, revenue trends, and popular services help the studio make informed business decisions and identify areas for growth.  
  *Business Analytics and Reporting*: The application provides detailed business analytics and reporting functionalities. Customizable reports on customer demographics, appointment trends, and service popularity enable the studio to gain valuable insights into their operations, supporting data-driven strategies.
  *Employee Management and Shift Scheduling*: Studio administrators can efficiently manage employee profiles, roles, and permissions through the application. The integrated shift scheduling feature ensures optimal staff coverage and smooth coordination between artists and other team members.  
  *Secure Data and Role-Based Access*: Data security is of utmost importance, and the application ensures robust measures to protect sensitive information. Role-based access control restricts data access only to authorized personnel, safeguarding customer details and business data.  
  *User-Friendly Interface and Scalability*: The web application boasts an intuitive user interface, making it easy for studio administrators to navigate and manage various tasks efficiently. Additionally, the application is designed to scale effortlessly as the studio grows, accommodating expanding operations and customer demands.
  *Customer Engagement and Loyalty Programs*: The platform enables the studio to implement customer engagement initiatives and loyalty programs. Personalized promotions, rewards for frequent customers, and referral incentives foster a loyal customer base, contributing to increased retention and business growth.  
  In conclusion, the Medussa Tattoo Studio Management Web Application stands as a vital asset in streamlining administrative tasks, elevating customer experience, and optimizing the studio's overall efficiency. With its robust features, data-driven insights, and commitment to data security, this application empowers the studio to focus on its artistry while maintaining a successful and well-organized business.`

  const description_Animal = `The Animal Face Classification project represents a remarkable advancement in the field of computer vision, where cutting-edge technologies converge with the natural world. Through intensive research and rigorous training, this project has successfully developed a highly accurate model capable of classifying different animal faces with unparalleled precision.
  *Unveiling the Wonders of Computer Vision*: Harnessing the power of computer vision, this project delves into the intricacies of animal facial features. The model has been meticulously trained on datasets encompassing various animal species, allowing it to discern and distinguish even subtle nuances in facial structures.  
  *A Symphony of Training and Machine Learning*: The heart of the project lies in its sophisticated machine learning algorithms. The model has undergone extensive training using state-of-the-art techniques such as convolutional neural networks (CNNs) and transfer learning. The result is a robust and adaptable model that demonstrates exceptional generalization across diverse animal species.  
  *Precise Classification and Real-Time Processing*: With a profound emphasis on accuracy, the animal face classification model achieves impressive classification results. Whether it's the distinct stripes of a tiger, the intricate patterns of a leopard, or the subtle uniqueness of a domestic pet, the model can swiftly identify and classify animal faces in real-time.  
  *A Glimpse into Wildlife Conservation*: Beyond its technological prowess, the Animal Face Classification project holds potential in wildlife conservation efforts. By enabling quick identification of individual animals, researchers and conservationists can better monitor and track endangered species, aiding in population studies and preservation initiatives.   
  *Ethics and Responsible AI*: The Animal Face Classification project adheres to ethical considerations and responsible AI practices. Data collection and usage prioritize the well-being of animals, promoting responsible stewardship of information while contributing to scientific understanding and conservation efforts.  
  *Paving the Way for Technological Innovations*: The Animal Face Classification project stands as a testament to the potential of computer vision in understanding and interacting with the natural world. Its implications extend far beyond classification, opening doors to groundbreaking research, innovative applications, and a deeper connection with the animal kingdom.  
  In conclusion, the Animal Face Classification project represents a triumph in computer vision, bridging the gap between technology and the marvels of nature. With its precise classifications, potential conservation applications, and implications in various domains, this project ignites new possibilities in the realm of AI-driven discoveries.`

  return (
    <div name='projects' className='bg-black  text-gray-200 w-full h-full'>
       {/* Container */}
       <div className='p-4 flex flex-col justify-center w-full h-full'>
        <div>
          <p className='text-4xl font-bold inline border-b-4 border-[#ff98dd]'>Projects</p>
          <p className='py-4'>Check out some of my recent projects !</p>
        </div>

        <div className='w-full h-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 text-center py-8 px-12'>
          
          <Card title='Website Medussa Tattoo' img_Index='5' description={description}/>
        
          <Card title='Medussa Tattoo Studio Management Web Application' img_Index='5' description={description_App} />
          
          <Card title='Animal Face Classification' img_Index='2' description={description_Animal} repo_link='https://github.com/Diegonaza/Computer-Vision' />
          
          <Card title='Computer Vision CNN' img_Index='3' description="Under Development" repo_link='https://github.com/Diegonaza/Siamese-CNN-One-Shot-Classification'/>
          
          <Card title='Semantic Analysis' img_Index='4' description="Under Development" repo_link='https://github.com/Diegonaza/Semantic-Analysis' />
          
          
          <Card title='Unity RTS Multiplayer Game' description="Under Development" img_Index='0' />
          
          
        </div>

       </div>
    </div>
  )
}

export default Projects
