import React from 'react'

function Contact() {
  return (
    <div name='contact' className='w-full h-screen bg-black flex justify-center items-center p-4'>
      <form method='POST' action='https://getform.io/f/d095ea35-26da-404f-8072-76a6c4c167a3' className='flex flex-col max-w-[600px] w-full'>
        <div className='pb-8'>
          <p className='text-4xl font-bold inline border-b-4 border-[#d2f8c1] text-gray-200'>Contact</p>
          <p className='text-gray-300 py-4'>Shoot me an email</p>
        </div>
        <input className='bg-[#ccd6f6] p-2' type="text" placeholder='Name' name='name'/>
        <input className='my-4 p-2 bg-[#ccd6f6]' type="email" placeholder='Email' name='email' />
        <textarea className='bg-[#ccd6f6] p-2' name='message' rows='10' placeholder='Message'></textarea>
        <button className='text-white border-2 hover:bg-indigo-400 hover:border-indigo-400 px-4 py-3 my-8 mx-auto flex items-center rounded-full hover:bg-opacity-20'>Send Message</button>
      </form>
    </div>
  )
}

export default Contact
