import React,{useState} from 'react'
import {FaBars, FaTimes, FaGithub, FaLinkedin,} from 'react-icons/fa'
import {HiOutlineMail} from 'react-icons/hi'
import {BsFillPersonLinesFill} from 'react-icons/bs'
import logo from '../assets/logo.png'
import {Link} from 'react-scroll'


const Navbar = () => {
  const [nav, setNav] = useState(false)
  const handleClick = ()=> setNav(!nav)
  return (
    
    <div className='fixed  w-full h-[80px] flex justify-between items-center px-4 bg-[#000000] text-gray-300 z-50'>
      <div>
        <img src={logo} alt="missing" style={{width:70, height:80}} className='absolute left-0 ml-2 mt-1 top-0 py-1 px-1 z-20' onClick={handleClick}/>
      </div>

      {/* Menu */}
        <ul className='hidden md:flex'>
          
          <li > <Link to='home' smooth={true} duration={700} >Home</Link></li> 
          <li > <Link to='about' smooth={true} duration={700} >About</Link></li> 
          <li > <Link to='skills' smooth={true} duration={700} >Skills</Link></li>
          <li > <Link to='projects' smooth={true} duration={700} >Projects</Link></li>
          <li > <Link to='contact' smooth={true} duration={700} >Contact</Link></li>
        </ul>
     

      {/* Hamburger */}
      <div onClick={handleClick} className='md:hidden z-10 absolute right-4'>
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      {/* Mobile Menu */}
      <div className={!nav ? 'hidden' :' absolute w-full h-screen inset-0 bg-black flex flex-col justify-center items-left md:hidden '}>
        <div className='z-50'>

        <div className='absolute w-[200px] h-[200px] left-[20%] top-[26%] bg-gradient-to-r from-[#7e7eff] from-33% via-[#ffbf6d] via-66% to-[#f02167] to-100% rounded-full mix-blend-multiply filter blur-lg opacity-10 animate-blob animation-delay-2000 '></div>  

        <div className='absolute w-[200px] h-[200px] left-[40%] top-[40%] bg-gradient-to-r from-[#f2cf08] from-33% via-[#e4fd02] via-66% to-[#5dfa86] to-100% rounded-full mix-blend-multiply filter blur-lg opacity-10 animate-blob '></div>

        <div className=' absolute w-[200px] h-[200px] left-[5%] top-[45%] bg-gradient-to-r from-[#b263fc] from-33% via-[#fba2d5] via-66% to-[#b6f2dd] to-100% rounded-full mix-blend-multiply filter blur-lg opacity-10 animate-blob animation-delay-4000'></div> 

                    

      </div>      
      
      <ul className=' absolute items-center left-[30%] 
      z-50'>
            <li className='py-4 text-2xl'> <Link onClick={handleClick} to='home' smooth={true} duration={700} >Home</Link> </li>
            <li className='py-4 text-2xl'> <Link onClick={handleClick} to='about' smooth={true} duration={700} >About</Link> </li>
            <li className='py-4 text-2xl'> <Link onClick={handleClick} to='skills' smooth={true} duration={700} >Skills</Link> </li>
            <li className='py-4 text-2xl'> <Link onClick={handleClick} to='projects' smooth={true} duration={700} >Projects</Link> </li>
            <li className='py-4 text-2xl'> <Link onClick={handleClick} to='contact' smooth={true} duration={700} >Contact</Link> </li>
          </ul>
        <div className='relative w-full h-full'>
        
        
          
        
        </div>
        
          
      </div>
      {/* Social Small */}
        <div className='md:hidden flex fixed flex-row bottom-0 l-0 gap-3'>
        
        <a className='flex justify-between items-center text-gray-300'
             href="https://www.linkedin.com/in/diego-pinheiro-74aa63207/">
               <FaLinkedin size={30} />
            </a>

        
            <a className='flex justify-between items-center  text-gray-300'
             href="https://github.com/Diegonaza">
               <FaGithub size={30} />
            </a>

            <a className='flex justify-between items-center  text-gray-300'
             href="/">
               <HiOutlineMail size={30} />
            </a>
            <a className='flex justify-between items-center text-gray-300'
             href="/">
               <BsFillPersonLinesFill size={30} />
            </a>

          
        </div>

      {/* Social icons */}
      <div className='hidden  md:flex fixed flex-col right-0 md:top-[33%] '>
        
        <ul>
          <li className='w-[160px] h-[60px] flex justify-between items-center mr-[-100px] hover:ml-[-100px] duration-300 bg-blue-600 rounded-sm' >
            <a className='flex justify-between items-center w-full text-gray-300'
             href="https://www.linkedin.com/in/diego-pinheiro-74aa63207/">
               <FaLinkedin size={30} />Linkedin
            </a>

          </li>

          <li className='w-[160px] h-[60px] flex justify-between items-center mr-[-100px] hover:ml-[-100px] duration-300 bg-[#1f1f1f] rounded-sm' >
            <a className='flex justify-between items-center w-full text-gray-300 '
             href="https://github.com/Diegonaza">
               <FaGithub size={30} />GitHub
            </a>

          </li>

          <li className='w-[160px] h-[60px] flex justify-between items-center mr-[-100px] hover:ml-[-100px] duration-300 bg-[#6fc2b0] rounded-sm' >
            <a className='flex justify-between items-center w-full text-gray-300'
             href="/">
               <HiOutlineMail size={30} />Email
            </a>

          </li>

          <li className='w-[160px] h-[60px] flex justify-between items-center mr-[-100px] hover:ml-[-100px] duration-300 bg-[#565f69] rounded-sm' >
            <a className='flex justify-between items-center w-full text-gray-300'
             href="/">
               <BsFillPersonLinesFill size={30} />Resume
            </a>

          </li>
        </ul>

      </div>
    </div>
    
  )
}

export default Navbar
