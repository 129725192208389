import React from 'react'
import img_HTML from '../assets/html.png'
import img_CSS from '../assets/css.png'
import img_REACT from '../assets/react.png'
import img_Node from '../assets/node.png'
import img_AWS from '../assets/aws.png'
import img_GitHub from '../assets/github.png'
import img_Tailwind from '../assets/tailwind.png'
import img_Mongo from '../assets/mongo.png'
import img_JavaScript from '../assets/javascript.png'

function Skills() {
  return (
    <div name='skills' className='bg-black  text-gray-200 w-full h-screen'>
       {/* Container */}
       <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div>
          <p className='text-4xl font-bold inline border-b-4 border-[#b263fc]'>Skills</p>
          <p className='py-4'>These are the technologies I have worked with</p>
        </div>

        <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
          <div className='shadow-md rounded-full shadow-[#040c16] hover:scale-110 duration-500'>
            <img className='w-20 mx-auto' src={img_REACT} alt="HTML icon" />
            <p>REACT</p>
          </div>
          <div className='shadow-md rounded-full shadow-[#040c16] hover:scale-110 duration-500'>
            <img className='w-20 mx-auto' src={img_Mongo} alt="HTML icon" />
            <p>MONGO DB</p>
          </div>
          <div className='shadow-md rounded-full shadow-[#040c16] hover:scale-110 duration-500'>
            <img className='w-20 mx-auto' src={img_GitHub} alt="HTML icon" />
            <p>GITHUB</p>
          </div>
          <div className='shadow-md rounded-full shadow-[#040c16] hover:scale-110 duration-500'>
            <img className='w-20 mx-auto' src={img_Tailwind} alt="HTML icon" />
            <p>TAILWIND</p>
          </div>
          <div className='shadow-md rounded-full shadow-[#040c16] hover:scale-110 duration-500'>
            <img className='w-20 mx-auto' src={img_CSS} alt="HTML icon" />
            <p>CSS</p>
          </div>
          <div className='shadow-md rounded-full shadow-[#040c16] hover:scale-110 duration-500'>
            <img className='w-20 mx-auto' src={img_AWS} alt="HTML icon" />
            <p>AWS</p>
          </div>
          <div className='shadow-md rounded-full shadow-[#040c16] hover:scale-110 duration-500'>
            <img className='w-20 mx-auto' src={img_JavaScript} alt="HTML icon" />
            <p>JAVASCRIPT</p>
          </div>
          <div className='shadow-md rounded-full shadow-[#040c16] hover:scale-110 duration-500'>
            <img className='w-20 mx-auto' src={img_HTML} alt="HTML icon" />
            <p>HTML</p>
          </div>
        </div>

       </div>
    </div>
  )
}

export default Skills
