import React from 'react'

const About = () => {
  return (
    <div name='about' className='w-full h-screen bg-black mt-[-2px] text-gray-500'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
          <div className='mas-w-[1000px] w-full grid grid-cols-2 gap-8'>
            <div className='sm:text-right pb-8 pl-4'>
              <div className='flex justify-center items-center h-full'>
                <div className='ml-[-10%] sm:ml-0 pb-2 bg-gradient-to-r from-[#b263fc] from-33% via-[#fba2d5] via-66% to-[#b6f2dd] to-100% '>
                <p className='text-gray-200  text-left mr-[-1px] ml-[-1px] text-4xl font-bold inline bg-black '>About</p>
                </div>
                <div></div>
              </div>
              
            </div>
          
            

          </div>
          <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
              <div className='sm:text-right text-2xl font-bold'>
                  <p className='text-gray-400 text-left'>Hi, I am Diego, nice to meet you, Please take a look around.</p>
              </div>
              <div>
                <p className='text-gray-500 text-left'>I am passionate about building excellent software that improves the lives of those around me. I specialize in creating software for clients ranging from individuals and small-businesses all the way to large enterprise corporations. What would you do if you had a software expert available at your fingertips ?</p>
              </div>
            </div>
       </div>
      
    </div>
  )
}

export default About
