import React from 'react'
import { useEffect, useRef } from 'react';
import {HiArrowNarrowRight} from 'react-icons/hi'
import {Link} from 'react-scroll'

function Home() {
  const tagRef = useRef([]);

  useEffect(() => {
    
    tagRef.current.forEach((element) => {
      element.classList.add('animate-startAnim');
    });
  }, []);

  return (
    <div name='home' className='w-full md:h-screen h-full pt-[115px] bg-[#000000]  '>
      <div className='max-w-[1000px] max-auto  px-8 flex flex-col justify-center h-full mt-[-5px]
       '>
        <p ref={(el) => (tagRef.current[0] = el)} className='text-gray-200 '>Hi, my name is</p>
        <h1 ref={(el) => (tagRef.current[1] = el)} className='text-2xl sm:text-7xl font-bold text-gray-400'>Diego Pinheiro</h1>
        <h2 ref={(el) => (tagRef.current[2] = el)} className='text-2xl sm:text-7xl font-bold text-gray-500'>I am a Full Stack Developer.</h2>
        <p ref={(el) => (tagRef.current[3] = el)} className='py-4 max-w-[700px] text-gray-300'>What to Expect:<br/>

<span className='font-bold'>Diverse Skill Set:</span> Front-end & Back-end Development<br/>
<span className='font-bold'>Innovative Projects:</span> Showcasing the Latest Technologies<br/>
<span className='font-bold'>Continuous Learning:</span> Staying Ahead in the Ever-Evolving Tech World<br/>
<span className='font-bold'>Collaborative Spirit:</span> Problem-Solving & Teamwork<br/>
<span className='font-bold'>User-Centric Design:</span> Delightful Interfaces for a Seamless Experience
Let's Collaborate!<br/>

I'm excited to work on dynamic web apps, stunning websites, and reliable back-end systems. Let's create solutions that make a positive impact together!

Thank You for Visiting:

Explore my projects, and feel free to reach out for collaboration ideas or to connect. Let's make technology come alive!<br/>
<br/>
Sincerely,<br/>
Diego Pinheiro</p>
        <div>
        <button className='text-white group border-2 rounded-sm px-6 py-3 my-2 flex items-center
        transition ease-in-out delay-250 
        hover:bg-gradient-to-r from-[#b263fc] from-33% via-[#fba2d5] via-66% to-[#b6f2dd] to-100% duration-500
        hover:-translate-y-1 hover:scale-110 
        '><Link to='projects' smooth={true} duration={700} >Projects</Link>
          <span className='transition ease-in-out delay-250 group-hover:rotate-90 group-hover:-translate-y-1 duration-500'>
            <HiArrowNarrowRight className='ml-4'/>
          </span> 
        </button>
        </div>
        
      </div>
    </div>
  )
}

export default Home
